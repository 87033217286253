@font-face {
  font-family: 'EYInterstate';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 300;
  src:
    local('EYInterstate'),
    local('EYInterstate-Light'),
    url('./assets/fonts/ey-interstate/EYInterstate-Light.woff2') format('woff2'),
    url('./assets/fonts/ey-interstate/EYInterstate-Light.woff') format('woff'),
    url('./assets/fonts/ey-interstate/EYInterstate-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'EYInterstate';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 400;
  src:
    local('EYInterstate'),
    local('EYInterstate-Regular'),
    url('./assets/fonts/ey-interstate/EYInterstate-Regular.woff2') format('woff2'),
    url('./assets/fonts/ey-interstate/EYInterstate-Regular.woff') format('woff'),
    url('./assets/fonts/ey-interstate/EYInterstate-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'EYInterstate';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 700;
  src:
    local('EYInterstate'),
    local('EYInterstate-Bold'),
    url('./assets/fonts/ey-interstate/EYInterstate-Bold.woff2') format('woff2'),
    url('./assets/fonts/ey-interstate/EYInterstate-Bold.woff') format('woff'),
    url('./assets/fonts/ey-interstate/EYInterstate-Bold.ttf') format('truetype')
  ,
}

@font-face {
  font-family: 'EYInterstate';
  font-style: 'itaic';
  font-display: 'swap';
  font-weight: 700;
  src:
    local('EYInterstate'),
    local('EYInterstate-BoldItalic'),
    url('./assets/fonts/ey-interstate/EYInterstate-BoldItalic.ttf') format('truetype')
  ,
}

@font-face {
  font-family: 'EYInterstate';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 700;
  src:
    local('EYInterstate'),
    local('EYInterstate-Black'),
    url('./assets/fonts/ey-interstate/EYInterstate-Black.woff') format('woff');
}